import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const PrivacyPolicyPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - Privacy Policy</title>
        </Helmet>
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9">
                    Privacy Policy
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-3">1. Our Privacy Policy</h3>
                    <p className="gr-text-9">
                      The health services provided by third-party or contracted health professionals, patient booking, management and telehealth software, the booking portal applications, and our website (all referred to as "Services") provided by Akkadian Pty. Ltd., trading as Akkadian Health, may be referred to as "we", "our" and "us" in this Privacy Policy. By accessing the website and using these Services, you agree to this Privacy Policy.
                    </p>
                    <p className="gr-text-9">
                      This Privacy Policy relates to the collection, use and handling of personal information you may supply to us through your use of our Services. The collection, use, and handling of personal information complies with the Australian Privacy Act 1988.
                    </p>
                    <p className="gr-text-9">
                      At Akkadian Health, we recognise the importance of protecting the privacy of information collected about visitors to our website and users accessing our Services, in particular information that may identify an individual ("personal information").
                    </p>
                    <p className="gr-text-9">
                      By accessing our Services, you agree to this Privacy Policy. You agree that using our Services, implies your consent to the collection, storage, and use of your personal information in accordance with this Privacy Policy.
                    </p>
                    <p className="gr-text-9">
                      This Privacy Policy is additional to any other terms and conditions applicable to our Services. We reserve the right to change this Privacy Policy from time to time without further notice to you. Any variations made will be updated on the website.
                    </p>
                    <p className="gr-text-9">
                      We welcome your comments and feedback via <a href="mailto:privacy@akkadianhealth.com">privacy@akkadianhealth.com</a>.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">2. Personal Information</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Your personal information is collected only when you consent to it being collected and when it is knowingly and voluntarily submitted.</strong>
                    </p>
                    <p className="gr-text-9">
                      We collect your personal information to enable third-party health care professionals to provide medical services. It is our intention that this Policy will protect your personal information from being dealt with in any way that is inconsistent with applicable privacy laws in Australia.
                    </p>
                    <p className="gr-text-9">
                      In order to be able to provide the Services to you, we will need your consent to collect your sensitive and personal information. This information is primarily collected at the time of booking an appointment via an online booking form and/or online booking portal application, or by other forms of contact, such as by personal contact, mail, telephone, email, video teleconferencing.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">3. Collecting Information</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">As part of registering with us to receive Services, we collect personal information about you</strong> in order for you to take full advantage of those Services. To do this it may be necessary for you to provide information to us as detailed below.
                    </p>
                    <p className="gr-text-9">
                      The personal information we collect is reasonably necessary for the purposes of conducting and improving our business and our products and Services. In providing the Services, some of the information we collect may be sensitive information including health information. This information may be collected through the website, through our Services, or by other forms of contact, such as by personal contact, mail, telephone, email, video teleconferencing, and secure messaging.
                    </p>
                    <p className="gr-text-9">
                      We may collect and store the clinical notes and / or clinical assessments of service providers who are registered with us, such as psychologists and other health providers (“Providers”) which relate to your consultation with the Provider if we are requested to do so by the Provider.
                    </p>
                    <p className="gr-text-9">
                     The details that may be collected at registration may include submitting your title, full name, email address, residential and postal address, telephone numbers, your gender, your date of birth, your marital status, your next of kin, your general practitioner’s name and contact number, your occupation and employment status, transactional information including method of payment and your consultation dates and times, other information such as your Medicare card details, Department of Veterans’ Affairs file number, and medical history shared in the form of a referral letter received from your referring General Practitioner.
                     </p>
                    <p className="gr-text-9">
                      We try to only collect personal information about you from you to maintain the accuracy of that information, and only where it is reasonable and necessary for our Services.
                    </p>
                    <p className="gr-text-9">
                    <strong className="font-weight-bold">Cookies: </strong>Cookies are files with a small amount of data. We may use cookies to collect information, to provide you with a better experience in our provision of our Services to you, to assist with the diagnosis of problems or support issues with our Services, and to understand the use of our Services. These cookies are a way of monitoring single user access without storing or retaining any personal information. Any aggregate, non-personal information is collated (for example, via Google Analytics) and is made available to us to assist in analysing the usage of our Website.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">4. Credit Card Details</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Credit Card details</strong> are used to process payments for the Services, which may include assessment and psychiatry Services. Your credit card may be charged with our fee to enable you to secure an appointment for our Services. Your credit card may be charged before or after the provision of Services, depending on the nature of the Services. We will advise you beforehand when Services will be charged and will obtain your prior consent to do so.
                    </p>
                    <p className="gr-text-9">
                      We use a third-party provider, Southern Payment Systems Pty Ltd (ABN: 46 154 451 582, trading as PIN Payments), to process any of your credit card payments. For partial or split payments over time, we use the PayPal services provided by PayPal Australia Pty Limited (ABN: 93 111 195 389) which holds an Australian Financial Services Licence number 304962.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">5. Use of Information</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Personal information is used only for the purpose for which it is submitted.</strong> Copies of correspondence, that may contain personal information, are stored as archives for record-keeping and back-up purposes only.
                    </p>
                    <p className="gr-text-9">
                      Sometimes a patient referral will be sent to us by email. Emails are stored by our email hosting provider external to us, and are stored in accordance with Google Business services security protocols and policies.
                    </p>
                    <p className="gr-text-9">
                      You may also be asked to provide an option on agreeing to receive updates and promotional material.
                    </p>
                  </div>                  
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">6. Disclosure</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Personal information may be disclosed in special situations</strong> where we have reason to believe that doing so is necessary to identify, contact or bring legal action against anyone damaging, injuring, or interfering (intentionally or unintentionally) with our rights or property, users, or anyone else who could be harmed by such activities. Also, we may disclose personal information when the law requires such disclosure, or because we reasonably believe that you may harm yourself, someone else, or our property.
                    </p>
                    <p className="gr-text-9">
                      Disclosure of personal information may be to our providers and affiliates. <strong className="font-weight-bold">We may engage third parties to provide you with Services on our behalf.</strong> In that circumstance, we may disclose your personal information to those third parties in order to meet your request for our Services. We disclose your personal information only with your express consent.
                    </p>
                    <p className="gr-text-9">
                      Otherwise than for the above, we will not use or disclose your personal information without your express consent.
                    </p>
                    <p className="gr-text-9">
                      Uploading or transmitting data and including your personal information may be handled by third parties. In such circumstances, we are not responsible for that uploading and transmission and you are responsible for your disclosure of your personal information.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">7. Security</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">We strive to ensure the security, integrity and privacy of personal information submitted to us</strong>, and we review and update our security measures in light of current technologies. Unfortunately, no data transmission over the Internet can be guaranteed to be totally secure.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">We will endeavour to take all reasonable steps to protect the personal information you may transmit to us or which we hold about you pursuant to our Services.</strong> Once we do receive your transmission, we will also make our best efforts to ensure its security on our systems and to keep this information accurate and up to date.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">Our employees and the contractors who provide services related to our information systems are obliged to respect the confidentiality of any personal information</strong> held by us. However, we will not be held responsible for events arising from unauthorised access to your personal information.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">8. Accessing and updating your personal information</h3>
                    <p className="gr-text-9">
                      If you wish to access or update your personal information, please contact us, and provide the necessary details. We will then amend your personal information, which will be done by our staff who are bound by confidentiality obligations.                   </p>
                  </div>                    
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">9. Breach</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">As required under the Privacy Act 1988, we will notify you and the Australian Information Commissioner if we suspect a breach relating to your personal information has occurred</strong> and there is a real risk of serious harm to you because of the breach.
                   </p>
                  </div>                  
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">10. External Links</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">We may provide links to third party websites.</strong> These linked sites are not under our control, and we cannot accept responsibility for the conduct of companies linked to our website or Services. Before disclosing your personal information on any other website or product, we advise you to examine the terms and conditions of using that website or product and its privacy statement.
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">11. Applicable Law</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">The Website and the Services are governed and construed according to the laws of the State of South Australia.</strong> By using our Website and/or our Services you agree to submit to the jurisdiction of the courts of South Australia.
                    </p>
                  </div>                  
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">12. Problems or Questions</h3>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">If we become aware of any ongoing concerns or problems with our Services, we will take these issues seriously</strong> and work to address these concerns.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">If you have any concerns </strong>relating to our collection, use or disclosure of personal information, or about how we manage your personal information, you may write to our Privacy Officer at <a href="mailto:privacy@akkadianhealth.com">privacy@akkadianhealth.com</a>. All complaints made will be dealt with in confidence. We will reasonably endeavour to provide a response within 30 days of receipt of your complaint.
                    </p>
                    <p className="gr-text-9">
                      <strong className="font-weight-bold">If you are not satisfied with our response, or for more information about privacy issues in Australia and protecting your privacy</strong>, visit the Office of the Australian Privacy Commissioner's web site; <a href="http://www.oaic.gov.au/">http://www.oaic.gov.au/</a>.
                    </p>
                    <p className="gr-text-9">
                      If have any further queries relating to this Privacy Policy, or you have a problem or complaint with the collection or use of your data, please contact our Privacy Officer: <a href="mailto:privacy@akkadianhealth.com">privacy@akkadianhealth.com</a>
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">Thank You!</h3>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default PrivacyPolicyPage;
